module Footer

open Feliz

let view =
    Html.div [
        prop.className "contacts"
        prop.children [
            Html.div [
                prop.className "contacts__logo"
                prop.children [
                    Html.img [
                            prop.src "./logos/Union.png"
                        ]
                    Html.img [
                        prop.src "./logos/Intersect.png"
                    ]
                ]
            ]
            Html.h2 [
                prop.className "title-secondary title-secondary_black"
                prop.text (Localization.t "contacts")
            ]
            Html.div [
                prop.className "contacts__items"
                prop.children [
                    Html.a [
                        prop.href "mailto:hello@functor.team"
                        prop.className "contacts__item"
                        prop.children [
                            Html.img [
                                prop.src "./logos/email.png"
                                prop.className "contacts__icon"
                            ]
                        ]
                    ]
                    Html.a [
                        prop.href "https://www.facebook.com/functor.team/"
                        prop.className "contacts__item"
                        prop.children [
                            Html.img [
                                prop.src "./logos/facebook.png"
                                prop.className "contacts__icon"
                            ]
                        ]
                    ]
                    Html.a [
                        prop.href "https://ru.linkedin.com/company/functor-team"
                        prop.className "contacts__item"
                        prop.children [
                            Html.img [
                                prop.src "./logos/linkedin.png"
                                prop.className "contacts__icon"
                            ]
                        ]
                    ]
                    Html.a [
                        prop.href "https://t.me/functorteam"
                        prop.className "contacts__item"
                        prop.children [
                            Html.img [
                                prop.src "./logos/telegram.png"
                                prop.className "contacts__icon"
                            ]
                        ]
                    ]
                ]
            ]
            Html.p [
                prop.className "paragraph paragraph_black paragraph_withOpacity"
                prop.text "© 2024 Functor"
            ]
        ]
    ]